import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FloatingLabel,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Tab,
  Tabs,
  Placeholder,
  ListGroup,
  Modal,
  Row,
  InputGroup,
} from "react-bootstrap";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import helpers from "../components/helpers";
import Images from "../components/images";
import Icon from "../components/icons";
import CheckIconAbsensi from "../components/checkAbsensiIconsRiwayat";
import FilterDate from "../components/filterdate";

// import dataRiwayat from "../assets/dataRiwayat";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

axios.defaults.baseURL = "https://apiabsensi.kazuyamedia.com/api";
axios.defaults.headers.post["Content-Type"] = "application/json";

function App() {
  const navigate = useNavigate();

  const [dataModel, setDataModel] = useState([]);
  const [mode, setMode] = useState("form");
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [processing, modeProcessing] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [show, setShow] = useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [title, setTitle] = useState("List data Pengguna");
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [iduser, setiduser] = useState("12345.01");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dataRiwayat, setdataRiwayat] = useState([]);

  const viewSearch = () => setShow(true);
  const handleClose = () => setShow(false);

  const Navigate = (key) => {
    navigate("/" + key, { replace: true });
  };

  const myStyle = {
    height: {
      minHeight: "100vh",
    },
  };

  // serach component
  const showAll = () => {
    setIsSkeleton(true);
     var param = {
      iduser: sessionStorage.getItem("IDUSER_ABSENSI", true),
      datefrom: "",
      dateto: "",
    };
     axios
      .get('Riwayat/riwayat', {
        params: param,
      })
      .then((res) => {
        setIsSkeleton(false);
        setdataRiwayat(res.data.Data);
        setDateFrom("")
        setDateTo("")
      }).catch((error) => {
        console.log("error");
      });
  };

  const getRiwayat = () => {
    setIsSkeleton(true);
    var url = `Riwayat/riwayat`;
    var param = {
      iduser: sessionStorage.getItem("IDUSER_ABSENSI", true),
      datefrom: dateFrom,
      dateto: dateTo,
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        setdataRiwayat(res.data.Data);
        handleClose()
        setTimeout(() => {
          setIsSkeleton(false);
        }, 1066);
        // console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
      toast("Message : " + message);
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    acceptPolicy();
    getRiwayat();
  }, []);

  return (
    <>
      <Container>
        <Row className="justify-content-md-center align-items-center">
          <Modal show={show} onHide={handleClose} animation={false}>
            {/* start for props */}
            <div className="modal-dialog bg-light mt-5" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <p className="mb-0">Tentukan tanggal mulai & tanggal akhir</p>
                </div>
                <div className="modal-body">
                  <InputGroup className="mb-0">
                    {/* <InputGroup.Text id="basic-addon1"><Icons.ArrowLeft/></InputGroup.Text> */}
                    <Form.Control
                      placeholder="00/00/0000"
                      aria-label="date from"
                      type="date"
                      onChange={(e) => setDateFrom(e.target.value)}
                      name={"datefrom"}
                      aria-describedby="basic-addon1"
                    />
                    <Form.Control
                      placeholder="00/00/0000"
                      aria-label="date to"
                      type="date"
                      onChange={(e) => setDateTo(e.target.value)}
                      name={"dateto"}
                      aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1" onClick={getRiwayat}>
                      <Icon.Searching />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </div>
            {/* end for props */}
          </Modal>
        </Row>
      </Container>
      <div
        id="navbar"
        className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom"
      >
        <div className="row align-items-center">
          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("Dashboard")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.HomeIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ScanQr")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.ScanQrIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ProfilSaya")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.SettingsIcon className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Navbar className="bg-default rounded-bottom">
              <Container fluid>
                <Navbar.Brand className="text-white">
                  <a onClick={(key) => Navigate("Dashboard")}>
                    <Icon.BackIcon />
                  </a>
                  <span className="ms-3">Riwayat Absensi</span>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end text-white">
                  <Navbar.Text className="pe-3" onClick={showAll}>
                    <Icon.Reload />
                  </Navbar.Text>
                  <Navbar.Text onClick={viewSearch}>
                    <Icon.ArrowsSort />
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          <div data-cy="card-info" className="col-12 px-0 mt-0">
            <div
              style={myStyle.height}
              data-cy="section-riwayat-Absensi"
              className="bg-white bg-gradient p-2 mx-0 mb-2"
            >
              {dataRiwayat.map((item, i) => {
                if (i + 1 <= 10) {
                if (item.CHECKOUT_AT_HOUR != null) {
                  var HourItem = item.CHECKOUT_AT_HOUR;
                  var DateItem = item.CHECKOUT_AT_DATE;
                } else {
                  var HourItem = item.CHECKIN_AT_HOUR;
                  var DateItem = item.CHECKIN_AT_DATE;
                }
                return isSkeleton ? (
                  <Placeholder
                    as={ListGroup}
                    className="my-2 p-0 rounded bg-gray-200 d-flex justify-content-between"
                    animation="wave"
                  >
                    <Placeholder as={ListItem} animation="wave">
                      <Placeholder
                        as={ListItemText}
                        xs={7}
                        className="py-2 ms-1 my-2 border-1 mb-auto"
                      />
                      <Placeholder
                        as={ListItemText}
                        xs={2}
                        className="py-2 ms-1 my-2 border-1 mb-auto"
                      />
                    </Placeholder>
                    <Placeholder as={ListItem} animation="wave">
                      <Placeholder
                        as={ListItemText}
                        xs={7}
                        className="py-2 ms-1 my-2 rounded border-1 mb-auto"
                      />
                      <Placeholder
                        as={ListItemText}
                        xs={5}
                        className="py-2 ms-1 my-2 rounded border-1 mb-auto bg-gray-100"
                      />
                      <Placeholder
                        as={ListItemText}
                        xs={1}
                        className="p-3 ms-1 my-2 rounded-circle border-1 mt-auto"
                      />
                    </Placeholder>
                  </Placeholder>
                ) : (
                  <div className="card bg-gray-200 border-0 shadow-sm list-group-item-action active mb-2">
                    <div className="card-body">
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <h6 className="card-title text-default fw-600">
                            {item.STATUS_ABSENSI}
                          </h6>
                        </div>
                        <div className="col-3 align-items-end text-end pe-0">
                          <h6 className="card-title">{HourItem}</h6>
                        </div>
                      </div>
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <p className="text-dark lt-spacing mb-0">
                            {DateItem}
                          </p>
                        </div>
                        <div className="col-3 align-items-end text-end pe-0">
                          <CheckIconAbsensi
                            dataRecord={{ status: item.STATUS_ABSENSI }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
